import { animated, config, useSpring } from '@react-spring/web';
import { css } from 'aphrodite';
import { observer } from 'mobx-react';
import * as React from 'react';
import { useEffect, useRef, useState } from 'react';
import { XIcon } from '../../../web/components/svgs/icons/XIcon';
import { useGamification } from '../../hooks';
import { brandPrimary } from '../../styles/colors';
import { MemePointsPlacement } from '../../viewModels/gamification';
import { TransparentButton } from '../TransparentButton';
import { LightningGraphic1, LightningGraphic2 } from '../svgs/LightningGraphic';
import { styleSheet } from './styles';

interface IProps {
	className?: string;
}

const overlayFrom = {
	opacity: 0,
};

const overlayTo = {
	opacity: 1,
};

const modalFrom = {
	opacity: 0,
	transform: 'translate3d(0, -100px, 0)',
};

const modalTo = {
	opacity: 1,
	transform: 'translate3d(0, 0, 0)',
};

const pointsFrom = {
	opacity: 0,
	transform: 'translate3d(-50%, -50%,  0) scale(0)',
};

const pointsTo = {
	opacity: 1,
	transform: 'translate3d(-50%, -50%,  0) scale(1)',
};

const MemeModalBase: React.FC<IProps> = ({ className = '' }) => {
	const gamification = useGamification();
	const [animate, setAnimate] = useState(false);
	const audio = useRef<HTMLAudioElement>(null);
	const overlaySpring = useSpring({
		config: config.gentle,
		from: overlayFrom,
		to: animate ? overlayTo : overlayFrom,
	});
	const modalSpring = useSpring({
		config: config.gentle,
		delay: 500,
		from: modalFrom,
		to: animate ? modalTo : modalFrom,
	});
	const pointsSpring = useSpring({
		config: config.gentle,
		delay: 1000,
		from: pointsFrom,
		to: animate ? pointsTo : pointsFrom,
	});

	useEffect(() => {
		// @ts-ignore
		setAnimate(!!gamification.meme);
		// @ts-ignore
		const url = gamification.meme?.sound?.url;
		if (url) {
			// @ts-ignore
			audio.current = new Audio(url);
			audio.current.play();
		}
		// @ts-ignore
	}, [gamification.meme]);

	const onCloseClick = () => {
		// @ts-ignore
		gamification.closeMeme();
		audio.current?.pause();
	};

	const getStyles = () => {
		const styles = [styleSheet.memePoints];

		// @ts-ignore
		switch (gamification.meme.theme.pointsPlacement) {
			case MemePointsPlacement.Bottom:
				styles.push(styleSheet.memePointsBottom);
				break;
			case MemePointsPlacement.BottomLeft:
				styles.push(styleSheet.memePointsBottomLeft);
				break;
			case MemePointsPlacement.BottomRight:
				styles.push(styleSheet.memePointsBottomRight);
				break;
			case MemePointsPlacement.Left:
				styles.push(styleSheet.memePointsLeft);
				break;
			case MemePointsPlacement.Right:
				styles.push(styleSheet.memePointsRight);
				break;
			case MemePointsPlacement.Top:
				styles.push(styleSheet.memePointsTop);
				break;
			case MemePointsPlacement.TopLeft:
				styles.push(styleSheet.memePointsTopLeft);
				break;
			case MemePointsPlacement.Center:
				styles.push(styleSheet.memePointsCenter);
				break;
			default:
				styles.push(styleSheet.memePointsTopRight);
				break;
		}

		return styles;
	};

	// @ts-ignore
	if (!gamification.meme) {
		return null;
	}

	// @ts-ignore
	const memeImageIsMp4 = gamification.meme.image.mimeType === 'video/mp4';
	const wrapperClass = memeImageIsMp4 ? styleSheet.memeVideoWrapper : styleSheet.memeImgWrapper;
	const containerClass = memeImageIsMp4 ? styleSheet.memeVideoContainer : styleSheet.memeImgContainer;

	return (
		<animated.div className={`${css(styleSheet.memeModalOverlay)} ${className}`} style={overlaySpring}>
			<TransparentButton className={css(styleSheet.closeMeme)} onClick={onCloseClick}>
				<XIcon fillColor={brandPrimary} height={12} width={12} />
			</TransparentButton>
			<LightningGraphic1
				className={css(styleSheet.lightningGraphic1)}
				// @ts-ignore
				type={gamification.meme.memeType}
				// @ts-ignore
				primaryColor={gamification.meme.theme.primaryColor}
				// @ts-ignore
				secondaryColor={gamification.meme.theme.secondaryColor}
				// @ts-ignore
				backgroundColor={gamification.meme.theme.backgroundColor}
			/>
			<LightningGraphic2
				className={css(styleSheet.lightningGraphic2)}
				// @ts-ignore
				type={gamification.meme.memeType}
				// @ts-ignore
				primaryColor={gamification.meme.theme.primaryColor}
				// @ts-ignore
				secondaryColor={gamification.meme.theme.secondaryColor}
				// @ts-ignore
				backgroundColor={gamification.meme.theme.backgroundColor}
			/>
			<animated.div className={css(styleSheet.memeModal)} style={modalSpring}>
				<div className={css(containerClass)}>
					<animated.div
						className={css(getStyles())}
						style={{
							...pointsSpring,
							// @ts-ignore
							background: gamification.meme.theme.pointsBackgroundColor,
						}}
					>
						{/* @ts-ignore */}
						{/* @ts-ignore */}
						<span style={{ color: gamification.meme.theme.pointsColor }}>{gamification.meme.value}</span>
						{/* @ts-ignore */}
						<span style={{ color: gamification.meme.theme.pointsColor }}>PTS</span>
					</animated.div>
					<div className={css(wrapperClass)}>
						{/* @ts-ignore */}
						{memeImageIsMp4 && <video src={gamification.meme.image?.url} autoPlay={true} controls={false} />}
						{/* @ts-ignore */}
						{!memeImageIsMp4 && <img src={gamification.meme.image?.url} />}
					</div>
				</div>
				{/* @ts-ignore */}
				{/* @ts-ignore */}
				{gamification.meme.phrase && <div className={css(styleSheet.memePhrase)}>{gamification.meme.phrase}</div>}
			</animated.div>
		</animated.div>
	);
};

export const MemeModal = observer(MemeModalBase);
