import { css } from 'aphrodite';
import * as React from 'react';
import { PhoneCallDirection } from '../../../../extViewmodels';
import { DialerPhoneCallStatus, useTelephony } from '../../../contexts/telephony';
import { styleSheet } from '../styles';

export const CallInfo: React.FC = () => {
	const { callerId, phoneCall, phoneCallStatus, telephonyConfiguration } = useTelephony();

	const isRinging = phoneCallStatus === DialerPhoneCallStatus.Ringing;
	const inboundFrom = callerId?.companyName || phoneCall?.phoneNumber?.e164;

	if (isRinging) {
		return (
			<div className={css(styleSheet.dialerCallInfo)}>
				<div className={css(styleSheet.dialerCallIncomingTitle)}>Incoming Call</div>
				<div className={css(styleSheet.dialerCallInfoSubText)}>{inboundFrom}</div>
			</div>
		);
	}

	const isConnectedToPhoneCall = phoneCallStatus === DialerPhoneCallStatus.Connected;
	const isDialing = phoneCallStatus === DialerPhoneCallStatus.Dialing;
	const isOutboundCall = phoneCall?.direction === PhoneCallDirection?.Outbound;

	if (isDialing) {
		return (
			<div className={css(styleSheet.dialerCallInfo)}>
				<div className={css(styleSheet.dialerCallIncomingTitle)}>Dialing</div>
				<div className={css(styleSheet.dialerCallInfoSubText)}>{phoneCall?.phoneNumber?.e164}</div>
			</div>
		);
	}

	if (!isConnectedToPhoneCall) {
		return null;
	}

	if (isOutboundCall) {
		return (
			<div className={css(styleSheet.dialerCallInfo)}>
				<div className={css(styleSheet.dialerCallInfoText)}>{phoneCall?.phoneNumber?.e164}</div>
				<div className={css(styleSheet.dialerCallInfoSubText)}>
					Calling from {telephonyConfiguration?.phoneNumber?.e164}
				</div>
			</div>
		);
	}

	return (
		<div className={css(styleSheet.dialerCallInfo)}>
			<div className={css(styleSheet.dialerCallInfoText)}>{inboundFrom}</div>
			<div className={css(styleSheet.dialerCallInfoSubText)}>{phoneCall?.phoneNumber?.e164}</div>
		</div>
	);
};
