import { Device } from '@twilio/voice-sdk';
import { css } from 'aphrodite';
import * as React from 'react';
import { DialerPhoneCallStatus, useTelephony } from '../../../contexts/telephony';
import { styleSheet } from '../styles';

export const DialerStatus: React.FC = () => {
	const { phoneCallStatus, dialerState } = useTelephony();

	const isRinging = phoneCallStatus === DialerPhoneCallStatus.Ringing;

	const isConnectedToPhoneCall = phoneCallStatus === DialerPhoneCallStatus.Connected;
	const isDialing = phoneCallStatus === DialerPhoneCallStatus.Dialing;

	if (!isConnectedToPhoneCall && !isDialing && !isRinging) {
		if (!dialerState) {
			return null;
		}

		const dialerStateFillColor =
			dialerState === Device.State.Registered
				? styleSheet.dialerStatusDotRegistered
				: dialerState === Device.State.Registering
					? styleSheet.dialerStatusDotRegistering
					: styleSheet.dialerStatusDotBad;

		const dialerStateLabel =
			dialerState === Device.State.Registered
				? 'Listening for incoming calls'
				: dialerState === Device.State.Registering
					? 'Registering for incoming calls'
					: 'Disconnected - refresh Coffee';

		return (
			<div className={css(styleSheet.dialerCallInfo)}>
				<div className={css(styleSheet.dialerStatus)}>
					<div className={css(styleSheet.dialerCallInfoText, styleSheet.dialerStatusDot, dialerStateFillColor)} />
					<div className={css(styleSheet.dialerCallInfoText)}>{dialerStateLabel}</div>
				</div>
			</div>
		);
	}

	return null;
};
