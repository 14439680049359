import { css } from 'aphrodite';
import * as React from 'react';
import { XIcon } from '../../../web/components/svgs/icons/XIcon';
import { styleSheet } from './styles';
import { white } from '../../styles/colors';

interface IProps {
	className?: string;
	onClick: () => void;
}

export const MrryCloseButton: React.FC<IProps> = ({ className = '', onClick }) => {
	return (
		<button className={`${css(styleSheet.mrryCloseButton)} ${className}`} onClick={onClick}>
			<XIcon fillColor={white} height={12} width={12} />
		</button>
	);
};
