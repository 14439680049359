import { StyleDeclarationValue, css } from 'aphrodite';
import { observer } from 'mobx-react';
import * as React from 'react';
import { FC, useEffect, useState } from 'react';
import { IRichContentEditorState } from '../../../extViewmodels';
import { ToolbarLight, convertRawRichTextContentStateToRichContentEditorState } from '../../../models/UiUtils';
import { useUserSession } from '../../../models/hooks/appStateHooks';
import { useModal } from '../../../models/hooks/useModal';
import { Button } from '../../../web/components/Button';
import { Checkbox } from '../../../web/components/Checkbox';
import {
	IRichContentDocumentEditorConfig,
	RichContentDocumentEditor,
} from '../../../web/components/richContent/RichContentDocumentEditor';
import { XIcon } from '../../../web/components/svgs/icons/XIcon';
import { useQueue } from '../../hooks/queue';
import { grayIconFill } from '../../styles/colors';
import { aidaBaseStyleSheet } from '../../styles/styles';
import { AidaNoteViewModel } from '../../viewModels/note';
import { DefaultAIDANoteContentCSS } from '../AidaNoteEditor';
import { BlueModal } from '../BlueModal';
import { SkipOptions } from '../QueueNoteEditor/SkipOptions';
import { TransparentButton } from '../TransparentButton';
import { styleSheet } from './styles';

interface IProps {
	className?: string;
	styles?: StyleDeclarationValue[];
}

const SkipEditorConfig: IRichContentDocumentEditorConfig = {
	autoresizeToFitContent: true,
	contentHorizontalPadding: 20,
	contentRawCss: DefaultAIDANoteContentCSS,
	maxHeight: 400,
	toolbar: `${ToolbarLight}`,
};

export const RemoveLeadCheckbox: FC<IProps> = observer(props => {
	const { className, styles = [] } = props;
	const queue = useQueue();
	const userSession = useUserSession();
	const [editorState, setEditorState] = useState<IRichContentEditorState>(
		convertRawRichTextContentStateToRichContentEditorState(new AidaNoteViewModel(userSession).rawContentState)
	);

	const resetEditor = () => {
		setEditorState(
			convertRawRichTextContentStateToRichContentEditorState(new AidaNoteViewModel(userSession).rawContentState)
		);
	};

	useEffect(() => {
		resetEditor();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [queue.companyId]);

	const removeLeadModal = useModal(
		false,
		() => {
			const note = new AidaNoteViewModel(userSession, {
				content: editorState.getRawRichTextContent(),
			});
			queue.setSkipNote(note);
		},
		[editorState],
		false,
		false
	);

	const onSkipChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		if (e.target.checked) {
			queue.setSkipReason(queue.skipReasons?.[0]);
			// @ts-ignore
			queue.setSkipNote(null);
			removeLeadModal.setIsOpen(true)();
		} else {
			// @ts-ignore
			queue.setSkipReason(null);
			// @ts-ignore
			queue.setSkipNote(null);
			resetEditor();
		}
	};

	const onTempSkipChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		if (e.target.checked) {
			queue.setSkipReason(queue.tempSkipReasons?.[0]);
			// @ts-ignore
			queue.setSkipNote(null);
			removeLeadModal.setIsOpen(true)();
		} else {
			// @ts-ignore
			queue.setSkipReason(null);
			// @ts-ignore
			queue.setSkipReason(null);
			resetEditor();
		}
	};

	const onCloseClick = () => {
		removeLeadModal.onRequestClose(null, true);
		// @ts-ignore
		queue.setSkipReason(null);
		resetEditor();
	};

	const onSaveClick = () => removeLeadModal.onRequestClose(queue.skipReason, false);

	const onEditorStateChanged = (content: IRichContentEditorState) => {
		setEditorState(content);
	};

	const isTempSkip = !!queue.tempSkipReasons?.find(x => x.name === queue.skipReason?.name);
	const isPermaSkip = !!queue.skipReasons?.find(x => x.name === queue.skipReason?.name);

	const renderHeader = () => {
		return (
			<div className={css(styleSheet.header)}>
				<div className={css(styleSheet.title)}>{`Reason for ${isTempSkip ? 'skipping' : 'removing'} ${
					queue.lead?.company?.name
				}?`}</div>
				<TransparentButton className={css(styleSheet.close)} onClick={onCloseClick}>
					<XIcon fillColor={grayIconFill} height={12} width={12} />
				</TransparentButton>
			</div>
		);
	};

	const noteRequired = queue.skipReason?.requireNote === true;
	const disabled = !queue.skipReason || (noteRequired && !editorState.hasContent());

	return (
		<div className={`${css(styleSheet.container, ...styles)} remove-lead-checkbox ${className || ''}`}>
			{!!queue.tempSkipReasons?.length && (
				<Checkbox id='skip-lead-checkbox' onChange={onTempSkipChange} checked={isTempSkip}>
					<span className={css(styleSheet.text)}>Skip for now</span>
				</Checkbox>
			)}
			{!!queue.skipReasons?.length && (
				<Checkbox id='remove-lead-checkbox' onChange={onSkipChange} checked={isPermaSkip}>
					<span className={css(styleSheet.text)}>Remove Lead</span>
				</Checkbox>
			)}
			<BlueModal className={css(styleSheet.removeLeadModal)} headerAccessory={renderHeader()} {...removeLeadModal}>
				<div className={css(styleSheet.mainContainer)}>
					<SkipOptions
						onReasonSelect={queue.setSkipReason}
						options={
							queue.tempSkipReasons.find(x => x.name === queue.skipReason?.name)
								? queue.tempSkipReasons
								: queue.skipReasons
						}
					/>
					<div className={css(styleSheet.editorContainer)}>
						<RichContentDocumentEditor
							autoFocus={true}
							className='note-editor-skip-editor'
							config={SkipEditorConfig}
							contentState={editorState}
							onContentStateChanged={onEditorStateChanged}
						/>
					</div>
				</div>
				<div className={css(styleSheet.buttonContainer)}>
					<Button
						label='Save'
						onClick={onSaveClick}
						disabled={disabled}
						className={css(disabled && aidaBaseStyleSheet.disabled)}
					/>
					<Button label='Cancel' kind='reverse' onClick={onCloseClick} />
					{noteRequired && <span className={css(styleSheet.noteRequired)}>Note Required</span>}
				</div>
			</BlueModal>
		</div>
	);
});
