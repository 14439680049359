import { css } from 'aphrodite';
import * as React from 'react';
import { XIcon } from '../../../../web/components/svgs/icons/XIcon';
import { white } from '../../../styles/colors';
import { aidaBaseStyleSheet } from '../../../styles/styles';
import { TransparentButton } from '../../TransparentButton';
import { DraggableIcon } from '../../svgs/icons/audio/DraggableIcon';
import { styleSheet } from '../styles';

export interface IProps {
	displayName: string;
	isActive: boolean;
	onClickToClose: () => void;
}

export const ModalHeader: React.FC<IProps> = ({ displayName, isActive, onClickToClose }) => {
	return (
		<div className={`${css(styleSheet.header)} drag-handle`}>
			<div className={css(aidaBaseStyleSheet.flexHorizontalCenter)}>
				<TransparentButton className={css(styleSheet.draggableButton)}>
					<DraggableIcon className={css(styleSheet.draggableIcon)} />
				</TransparentButton>
				<div className={css(styleSheet.title)}>{`Email ${displayName}`}</div>
			</div>
			<div className={css(aidaBaseStyleSheet.flexHorizontalCenter)}>
				{/* @ts-ignore */}
				<TransparentButton className={css(styleSheet.close)} onClick={isActive ? null : onClickToClose}>
					<XIcon fillColor={white} height={14} width={14} />
				</TransparentButton>
			</div>
		</div>
	);
};
