import { animated, config, useSpring } from '@react-spring/web';
import { css } from 'aphrodite';
import { observer } from 'mobx-react';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { FormFieldType } from '../../../extViewmodels';
import { Topics } from '../../../models/LocalNotificationTopics';
import { postNotification } from '../../../models/LocalNotifications';
import { useUserSession } from '../../../models/hooks/appStateHooks';
import { Button } from '../../../web/components/Button';
import { LoadingSpinner } from '../../../web/components/LoadingSpinner';
import { XIcon } from '../../../web/components/svgs/icons/XIcon';
import { DuplicateIcon } from '../../../web/components/svgs/icons/DuplicateIcon';
import { ExpandIcon } from '../../../web/components/svgs/icons/ExpandIcon';
import { useDealModal } from '../../contexts/dealModal';
import { calculateDraggableWindowZIndex, useDraggableWindowContext } from '../../contexts/draggableWindowContext';
import { useTelephony } from '../../contexts/telephony';
import { useErrorMessages, useGamification, useToaster } from '../../hooks';
import { useQueue } from '../../hooks/queue';
import { white } from '../../styles/colors';
import { aidaBaseStyleSheet } from '../../styles/styles';
import { AidaNoteViewModel } from '../../viewModels/note';
import { ISkipLeadRequest } from '../../viewModels/queue';
import { AidaDraggable } from '../AidaDraggable';
import { TransparentButton } from '../TransparentButton';
import { AnimatedLionGraphic } from '../svgs/AnimatedLionGraphic';
import { HexGraphic } from '../svgs/HexGraphic';
import { DealField } from './DealField';
import { styleSheet } from './styles';

interface IProps {
	className?: string;
}

const dealModalWindowId = 'dealModal';

const DealModalBase: React.FC<IProps> = ({ className = '' }) => {
	const queue = useQueue();
	const { phoneCalls } = useTelephony();
	const gamification = useGamification();
	const userSession = useUserSession();
	const toaster = useToaster();
	const dealModal = useDealModal();
	const errorMessages = useErrorMessages();
	const windowContext = useDraggableWindowContext();
	const [draggable, setDraggable] = useState(false);

	const bringToFront = () => windowContext.bringToFront(dealModalWindowId);

	const modalSpring = useSpring({
		config: config.default,
		from: { right: -500 },
		to: { right: dealModal.showDealModal ? 0 : -500 },
	});

	useEffect(() => {
		windowContext.registerWindow(dealModalWindowId);
		return () => windowContext.unregisterWindow(dealModalWindowId);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (queue.lead?.dealForm && !queue.lead?.dealForm?.form) {
			queue.lead.dealForm
				.loadForm()
				.then(() => {
					bringToFront();
					const fields = queue.lead.dealForm.fields.filter(x => x.fieldType === FormFieldType.Note);
					fields.forEach(field => {
						if (field.name === 'ExecutiveSummaryNote' && dealModal.note) {
							field.note = new AidaNoteViewModel(userSession, dealModal.note);
						} else if (field.name === 'OutcomeNotes' && dealModal.interactionNote) {
							field.note = new AidaNoteViewModel(userSession, dealModal.interactionNote);
						}
					});
				})
				.catch(err => {
					// @ts-ignore
					errorMessages.pushApiError(err);
				});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [queue.lead?.dealForm]);

	useEffect(() => {
		// @ts-ignore
		dealModal.setNote(null);
		// @ts-ignore
		dealModal.setInteractionNote(null);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [queue.companyId]);

	const onCloseClick = () => {
		setDraggable(false);
		dealModal.setShowDealModal(false);
	};

	const onActionClick = () => {
		const validationErrors = queue.lead?.dealForm?.form?.fields.filter(field => {
			field.validate();
			if (field.validationError) {
				document.getElementById(field.id + field.label + field.fieldType)?.scrollIntoView({ behavior: 'smooth' });
			}
			return !!field.validationError;
		});

		if (validationErrors?.length === 0) {
			const customFormData: ISkipLeadRequest = {
				leadServedSource: queue.leadServedSource,
			};

			const phoneCallId = phoneCalls.currentCall?.id;
			if (phoneCallId && phoneCallId !== 'manual') {
				customFormData.phoneCallId = phoneCallId;
			}

			queue.lead?.dealForm
				.save(customFormData)
				.then(meme => {
					if (meme) {
						// @ts-ignore
						gamification.setMeme(meme);
					}

					// @ts-ignore
					toaster.push({
						message: meme ? 'Deal created successfully' : 'Deal updated successfully',
						type: 'successMessage',
					});

					postNotification({
						topic: Topics.TIMELINE_REFRESH,
					});

					dealModal.setShowDealModal(false);
					phoneCalls.clearCurrentCall();
					// @ts-ignore
					queue.reloadLead().catch(err => errorMessages.pushApiError(err));
				})
				.catch(err => {
					// @ts-ignore
					errorMessages.pushApiError(err);
				});
		}
	};

	const renderDealForm = () => {
		if (queue.lead?.dealForm?.loadingForm) {
			return <LoadingSpinner type='large' />;
		}

		const fields = queue.lead?.dealForm?.form?.fields.map(field => (
			<DealField className={css(styleSheet.dealField)} field={field} form={queue.lead.dealForm} key={field.id} />
		));

		return (
			<div className={css(styleSheet.dealForm)}>
				{fields}
				{queue.lead?.dealForm?.saving && <LoadingSpinner className={css(aidaBaseStyleSheet.absoluteCenter)} />}
			</div>
		);
	};

	const toggleDraggable = (e: React.MouseEvent) => {
		e.stopPropagation();
		setDraggable(!draggable);
	};

	const label = queue.lead?.dealForm?.dealId
		? queue.lead?.dealForm?.saving
			? 'Updating...'
			: 'Update'
		: queue.lead?.dealForm?.saving
			? 'Creating...'
			: 'Create';

	const dealModalContents = (
		<animated.div
			className={`${css(styleSheet.dealModal)} ${className}`}
			style={{
				zIndex: calculateDraggableWindowZIndex(windowContext.windowIds, dealModalWindowId),
				...modalSpring,
			}}
			onClick={bringToFront}
		>
			<div className={`${css(styleSheet.header)} drag-handle`}>
				<div className={css(styleSheet.headerMain)}>
					<div className={css(styleSheet.hexContainer)}>
						<HexGraphic animate={false} className={css(styleSheet.hex)} selected={true} />
						<AnimatedLionGraphic animate={false} className={css(styleSheet.lion)} />
					</div>
					<span className={css(styleSheet.headerTitle, styleSheet.title)}>
						{queue.lead?.dealForm?.dealId ? 'Update Deal' : 'Create Deal'}
					</span>
				</div>
				<TransparentButton onClick={toggleDraggable}>
					{draggable ? (
						<ExpandIcon fill={white} className={css(styleSheet.icon)} />
					) : (
						<DuplicateIcon fillColor={white} className={css(styleSheet.icon)} />
					)}
				</TransparentButton>
				<button className={css(styleSheet.close)} onClick={onCloseClick}>
					<XIcon fillColor={white} height={12} width={12} />
				</button>
			</div>
			{renderDealForm()}
			{!(queue.lead?.dealForm?.loadingForm || queue.lead?.dealForm?.fields?.some(x => x?.isBusy)) && (
				<div className={css(styleSheet.ctaContainer)}>
					<Button
						className={css(queue.lead?.dealForm?.saving && styleSheet.disabled)}
						disabled={queue.lead?.dealForm?.saving}
						label={label}
						onClick={onActionClick}
					/>
					<Button
						className={css(queue.lead?.dealForm?.saving && styleSheet.disabled)}
						kind='reverse'
						label='Cancel'
						disabled={queue.lead?.dealForm?.saving}
						onClick={onCloseClick}
					/>
				</div>
			)}
		</animated.div>
	);

	return (
		<AidaDraggable isDraggable={draggable} axis='x'>
			{dealModalContents}
		</AidaDraggable>
	);
};

export const DealModal = observer(DealModalBase);
